import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../config";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./pages/ScrollToTop";
import { useEffect } from "react";
import { checkAutoLogin } from "../services/AuthService";
/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import Jobs from "./components/Dashboard/Jobs";
import Applications from "./components/Dashboard/Applications";
import MyProfile from "./components/Dashboard/MyProfile";
import Statistics from "./components/PlacementStatistics/Statistics";
import Companies from "./components/Dashboard/Companies";
import Task from "./components/Dashboard/Task";

//Jobs
import JobLists from "./components/Jobs/JobLists";
import JobView from "./components/Jobs/JobView";
import JobApplication from "./components/Jobs/JobApplication";
// import ApplyJob from "./components/Jobs/ApplyJob";
// import NewJob from "./components/Jobs/NewJob";
import ApplicationDetails from "./components/Jobick/Jobs/ApplicationDetails";
import InviteStudent from "./components/Jobs/InviteStudent";
import { useLocation } from "react-router-dom";
// // App
// import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
// import Compose from "./components/AppsMenu/Email/Compose/Compose";
// import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
// import Read from "./components/AppsMenu/Email/Read/Read";
// import Calendar from "./components/AppsMenu/Calendar/Calendar";
// import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

// // Product List
// import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
// import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
// import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
// import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
// import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
// import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
// import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiMediaObject from "./components/bootstrap/MediaObject";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";
import ImportStudent from "./components/Jobs/ImportStudent";
import { useState } from "react";
/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
//import Nestable from "./components/PluginsMenu/Nestable/Nestable";
import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

//Redux
import Todo from "./pages/Todo";
//import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
//import WizardForm from "./components/Forms/ReduxWizard/Index";

/// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
// import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

//student
import UserProfile from "./components/Students/UserProfile";
import FilteringTable, {
  StudentsList,
} from "./components/Students/StudentsList";
import OfferTable from "./components/Students/OfferTable";
import InternshipTable from "./components/Students/InternshipTable";
import InternshipOfferDetails from "./components/Students/InternshipOfferDetails";
import JobOfferDetails from "./components/Students/JobOfferDetails";
import ChangePassword from "./pages/ChangePassword";
/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import TwoFactor from "./pages/TwoFactor";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const collegeId = useSelector((state) => state.college_id.college_id);

  // const dispatch = useDispatch();
  // const history = useHistory();
  const Location = useLocation();
  const routes = [
    /// Dashboard
    // { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "dashboard-dark", component: DashboardDark },
    { url: "search-jobs", component: Jobs },
    { url: "applications", component: Applications },
    { url: "my-profile", component: MyProfile },
    { url: "statistics", component: Statistics },
    { url: "companies", component: Companies },
    { url: "task", component: Task },
    { url: "job-offer-details", component: JobOfferDetails },
    { url: "internship-offer-details", component: InternshipOfferDetails },

    // Jobs
    { url: "job-list", component: JobLists },
    { url: "job-view", component: JobView },
    { url: "students-activity", component: JobApplication },
    { url: "application-details", component: ApplicationDetails },
    { url: "invite-student", component: InviteStudent },
    { url: "import-student", component: ImportStudent },

    // Charts
    { url: "chart-sparkline", component: SparklineChart },
    { url: "chart-chartjs", component: ChartJs },
    { url: "chart-chartist", component: Chartist },
    { url: "chart-apexchart", component: ApexChart },
    { url: "chart-rechart", component: RechartJs },

    // Bootstrap
    { url: "ui-alert", component: UiAlert },
    { url: "ui-badge", component: UiBadge },
    { url: "ui-button", component: UiButton },
    { url: "ui-modal", component: UiModal },
    { url: "ui-button-group", component: UiButtonGroup },
    { url: "ui-accordion", component: UiAccordion },
    { url: "ui-list-group", component: UiListGroup },
    { url: "ui-media-object", component: UiMediaObject },
    { url: "ui-card", component: UiCards },
    { url: "ui-carousel", component: UiCarousel },
    { url: "ui-dropdown", component: UiDropDown },
    { url: "ui-popover", component: UiPopOver },
    { url: "ui-progressbar", component: UiProgressBar },
    { url: "ui-tab", component: UiTab },
    { url: "ui-pagination", component: UiPagination },
    { url: "ui-typography", component: UiTypography },
    { url: "ui-grid", component: UiGrid },

    // Plugins
    { url: "uc-select2", component: Select2 },
    { url: "uc-noui-slider", component: MainNouiSlider },
    { url: "uc-sweetalert", component: MainSweetAlert },
    { url: "uc-toastr", component: Toastr },
    { url: "map-jqvmap", component: JqvMap },
    { url: "uc-lightgallery", component: Lightgallery },

    // Redux
    { url: "todo", component: Todo },

    // Widgets
    { url: "widget-basic", component: Widget },

    // Forms
    { url: "form-element", component: Element },
    { url: "form-wizard", component: Wizard },
    { url: "form-editor-summernote", component: SummerNote },
    { url: "form-pickers", component: Pickers },
    { url: "form-validation-jquery", component: jQueryValidation },

    // Tables
    { url: "table-sorting", component: SortingTable },
    { url: "table-datatable-basic", component: DataTable },
    { url: "table-bootstrap-basic", component: BootstrapTable },

    // Students
    { url: "students-list", component: StudentsList },
    { url: "user-profile", component: UserProfile },
    { url: "offer-table", component: OfferTable },
    { url: "internship-table", component: InternshipTable },

    // Placement Statistics
    { url: "statistics", component: Statistics },

    // Pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "two-factor-auth", component: TwoFactor },
    { url: "page-change-password", component: ChangePassword },
    // Error Pages
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
  ];

  // useEffect(() => {
  //   checkAutoLogin(dispatch, history);
  // }, [dispatch, history]);

  // const [isFirst, setisfirst] = useState(true);
  // const [isFirst, setIsFirst] = useState(true);

  // const fetchCollegeProfile = async () => {
  //   const payload1 = { college_id: collegeId };
  //   try {
  //     const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  //     const authToken = userDetails?.data?.auth_token;

  //     if (!authToken) {
  //       throw new Error("Authorization token not found");
  //     }

  //     console.log(authToken);

  //     const response = await axios.get(
  //       `${API_URL}/api/freshment_portal_apis/College/get_college_profile`,
  //       {
  //         params: payload1,
  //         headers: {
  //           Authorization: authToken,
  //         },
  //       }
  //     );

  //     console.log(response);
  //     const collegeData = response.data.data;

  //     localStorage.setItem("collegeProfile", JSON.stringify(collegeData));
  //     // setCollegeObj(collegeData);
  //   } catch (err) {
  //     console.log("Error fetching profile:", err);
  //   }
  // };

  // useEffect(() => {
  //   if (isFirst) {
  //     fetchCollegeProfile();
  //     setIsFirst(false);
  //   }
  // }, [isFirst]); // Added isFirst as a dependency

  let path = Location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  // let pathonce = Location.pathname;
  // pathonce = pathonce.split("/");
  // let forgotpath = pathonce.includes("page-change-password");
  let pagePath = path.split("-").includes("page");
  // console.log(forgotpath, pathonce);
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => {
                if (data.url === "job-view") {
                  return (
                    <Route
                      path={`/${data.url}/:job`} // Capture the job parameter
                      key={i}
                      component={data.component} // Render the component
                    />
                  );
                } else if (data.url === "user-profile") {
                  return (
                    <Route
                      path={`/${data.url}/:studentId`}
                      key={i}
                      component={data.component}
                    />
                  );
                } else if (data.url === "application-details") {
                  return (
                    <Route
                      path={`/${data.url}/:type/:id`}
                      key={i}
                      component={data.component}
                    />
                  );
                } else if (data.url === "page-change-password") {
                  return (
                    <Route
                      path={`/${data.url}/:collegeid`}
                      key={i}
                      component={data.component}
                    />
                  );
                } else if (
                  data.url === "job-offer-details" ||
                  data.url === "internship-offer-details"
                ) {
                  return (
                    <Route
                      path={`/${data.url}/:offerId`}
                      key={i}
                      component={data.component}
                    />
                  );
                } else {
                  return (
                    <Route
                      path={`/${data.url}`} // Regular route
                      key={i}
                      component={data.component} // Render the component
                    />
                  );
                }
              })}
              <Route path="*" render={() => <Redirect to="/dashboard" />} />
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      {/* <Setting /> */}
      <ScrollToTop />
    </>
  );
};

export default Markup;
