import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";

export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + tokenDetails.expiresIn * 1000
  );

  // console.log(tokenDetails.expireDate);
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}
let logoutTimer; // Store the timer ID for clearable timeout

export function runLogoutTimer(dispatch, timer, history) {
  // console.log(`Logout timer set for ${timer}ms.`);

  // Clear any existing timer to prevent duplicate ones
  if (logoutTimer) clearTimeout(logoutTimer);

  // Set the new timer
  logoutTimer = setTimeout(() => {
    // Show SweetAlert before logging out
    swal({
      title: "Session Expired",
      text: "Your authentication session has expired. HTTP 401 Error.",
      icon: "warning",
      buttons: ["OK"],
    }).then((willLogout) => {
      if (willLogout) {
        dispatch(logout(history));
        localStorage.removeItem("userDetails"); // Clear stored token
      } else {
        dispatch(logout(history));
        localStorage.removeItem("userDetails"); // Clear stored token
      }
    });
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  // console.log(tokenDetailsString);

  if (!tokenDetailsString) {
    dispatch(logout(history));
    return;
  }

  const tokenDetails = JSON.parse(tokenDetailsString);
  const expiringDate = new Date(tokenDetails.expireDate);
  const currentDate = new Date();

  // console.log(`Token Details:`, tokenDetails);

  // Check if the token has already expired
  if (currentDate >= expiringDate) {
    dispatch(logout(history));
    return;
  }

  dispatch(loginConfirmedAction(tokenDetails));

  // Calculate the remaining time before logout
  const remainingTime = expiringDate.getTime() - currentDate.getTime();
  // console.log(`Remaining time in ms: ${remainingTime}`);

  // Run the logout timer with the remaining time
  runLogoutTimer(dispatch, remainingTime, history);
}
