// reducers/CategoryReducer.js
const initialState = {
  job_category: [],
};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_JOB_CATEGORY":
      return {
        ...state,
        job_category: action.payload,
      };
    default:
      return state;
  }
};

export default CategoryReducer;
