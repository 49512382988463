import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import ProfileStrength from "../Jobick/Statistics/ProfileStrength";
import VacanciesStatsTab from "../Jobick/Statistics/VacanciesStatsTab";
import CanvasChartTab from "../Jobick/Home/CanvasChartTab";
import FeaturedCompanies from "../Jobick/Home/FeaturedCompanies";
import axios from "axios";
import TopRoles from "../Jobick/Home/TopRoles";
import "../table/FilteringTable/filtering.css";
import { Dropdown } from "react-bootstrap";
import { API_URL } from "../../../config";
import { useSelector } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import { useDispatch } from "react-redux";
import { setCourses } from "../../../store/actions/CourseAction";
// import { useSelector } from "react-redux";
const Statistics = () => {
  const collegeId = useSelector((state) => state.college_id.college_id);
  const [statistics, setPlacementStatistics] = useState([]);
  const [jobStatistics, setJobStatistics] = useState([]);
  const [placementCharts, setPlacementCharts] = useState([]);
  const payload = { college_id: collegeId };
  const currentYear = new Date().getFullYear();
  //start filter
  const courses = useSelector((state) => state.courses.courses); // Access the courses data
  // console.log(courses);
  const [studentList, setStudentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [studentsPerPage] = useState(10); // Number of students to display per page
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [passoutYear, setPassoutYear] = useState("Passout Year");
  const [course, setCourse] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [jobType, setJobType] = useState(0);
  // const payload = { college_id: 1, offset: 0 };

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [courseId, setCourseId] = useState(null);
  const [masterCourseId, setMasterCourseId] = useState(null);
  const [specId, setSpecId] = useState(null);
  const [passoutYearForm, setPassoutYearForm] = useState("");
  const [specializationData, setSpecializationData] = useState([]);
  const [interestedPlacement, setInterestedPlacement] = useState(1);
  const [interestedHigherStudy, setInterestedHigherStudy] = useState(1);
  const [studentId, setStudentId] = useState(null);
  // const currentYear = new Date().getFullYear();
  const [courseList, setCourseList] = useState("Choose Course");
  const [courseIdList, setCourseIdList] = useState(null);
  const [specialization, setSpecialization] = useState("Choose Specialization");
  const [specializationId, setSpecializationId] = useState(null);
  const [coursefilterId, setCourseIdfilter] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    const storedCourses = localStorage.getItem("courses");
    if (storedCourses) {
      dispatch(setCourses(JSON.parse(storedCourses)));
    }
  }, [dispatch]);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    if (courseId != null) {
      const payload = { college_id: collegeId, course_id: courseId };
      axios
        .get(
          `${API_URL}/api/freshment_portal_apis/GenericAPI/get_course_spealization_list`,
          {
            params: payload,
            headers: {
              Authorization: authToken,
            },
          }
          // {
          //   headers: {
          //     "Access-Control-Allow-Origin": "*",
          //     "Access-Control-Allow-Headers":
          //       "Origin, X-Requested-With, Content-Type, Accept, Authorization",
          //     "Content-Type":
          //       "application/x-www-form-urlencoded; charset=UTF-8;application/json",
          //   },
          // }
        )
        .then((response) => {
          setSpecializationData(response.data.data);
          // console.log(response.data.data);
        })
        .catch((err) => console.log("err======", err));
    } else if (coursefilterId != null) {
      const payload = { college_id: collegeId, course_id: coursefilterId };
      axios
        .get(
          `${API_URL}/api/freshment_portal_apis/GenericAPI/get_course_spealization_list`,
          {
            params: payload,
            headers: {
              Authorization: authToken,
            },
          }
          // {
          //   headers: {
          //     "Access-Control-Allow-Origin": "*",
          //     "Access-Control-Allow-Headers":
          //       "Origin, X-Requested-With, Content-Type, Accept, Authorization",
          //     "Content-Type":
          //       "application/x-www-form-urlencoded; charset=UTF-8;application/json",
          //   },
          // }
        )
        .then((response) => {
          setSpecializationData(response.data.data);
          // console.log(response.data.data);
        })
        .catch((err) => console.log("err======", err));
    }
  }, [courseId, coursefilterId]);

  useEffect(() => {
    if (masterCourseId !== null) {
      const coursemaster = courses?.find(
        (courses) => courses?.course_master_id == masterCourseId
      );
      setCourseId(coursemaster.college_course_id);
    }
  }, [masterCourseId, course]);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    const payload = { college_id: collegeId };
    axios
      .get(`${API_URL}/api/freshment_portal_apis/GenericAPI/get_courses_list`, {
        params: payload,
        headers: {
          Authorization: authToken,
        },
      })
      .then((response) => {
        setCourse(response.data.data);
      })
      .catch((err) => console.log("err======", err));
  }, []);

  useEffect(() => {
    // Update payload properties directly without reassigning the object
    if (
      courseIdList != null &&
      courseList != "Choose Course" &&
      courseIdList != "Choose Course"
    ) {
      payload.filter_by_course = parseInt(courseIdList);
    } else {
      delete payload.filter_by_course;
    }

    if (specializationId != null && specialization != "Choose Specialization") {
      payload.filter_by_specialization = parseInt(specializationId);
    } else {
      delete payload.filter_by_specialization;
    }

    if (passoutYear != null && passoutYear !== "Passout Year") {
      payload.filter_by_passout_year = passoutYear;
    } else {
      delete payload.filter_by_passout_year;
    }

    if (jobType != null) {
      payload.filter_by_job_type = jobType;
    } else {
      delete payload.filter_by_job_type;
    }
    console.log(payload);
    fetchPlacementStatistics();
    fetchJobStatistics();
    fetchPlacementCharts();
  }, [courseIdList, specializationId, passoutYear, jobType]);

  //till filter
  const fetchPlacementStatistics = () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    axios
      .get(
        `${API_URL}/api/freshment_portal_apis/PlaceOffer/get_placement_statistics`,
        {
          params: payload,
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((response) => {
        setPlacementStatistics(response.data.data);
        console.log(response.data.data);
      })
      .catch((err) => console.log("err======", err));
  };
  const fetchJobStatistics = () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    axios
      .get(
        `${API_URL}/api/freshment_portal_apis/PlaceOffer/get_job_stats_charts`,
        {
          params: payload,
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((response) => {
        setJobStatistics(response.data.data);
        console.log(response.data.data);
      })
      .catch((err) => console.log("err======", err));
  };
  const fetchPlacementCharts = () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    axios
      .get(
        `${API_URL}/api/freshment_portal_apis/PlaceOffer/get_placement_charts`,
        {
          params: payload,
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((response) => {
        setPlacementCharts(response.data.data);
        console.log(response.data.data);
      })
      .catch((err) => console.log("err======", err));
  };
  // useEffect(() => {
  //   fetchPlacementStatistics();
  //   fetchJobStatistics();
  //   fetchPlacementCharts();
  // }, []);

  return (
    <>
      {/* Filter Data */}
      <PageTitle
        activeMenu="Statistics"
        motherMenu="Placement"
        // pageContent="Placement Statistics"
      />
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className="btn-group" role="group">
            <button
              onClick={() => setJobType(0)}
              className={`btn btn-md ${
                jobType === 0 ? "btn-primary" : "btn-outline-primary"
              } `}
            >
              Jobs
            </button>
            <button
              onClick={() => setJobType(1)}
              className={`btn btn-md ${
                jobType === 1 ? "btn-primary" : "btn-outline-primary"
              }`}
            >
              Internship
            </button>
          </div>
        </div>
        <div
          className="d-flex align-items-center  flex-wrap search-job bg-white px-0 mb-3"
          style={{ width: "60%" }}
        >
          <div className="col-xl-4 col-xxl-4 col-lg-4 col-sm-4 col-12 search-dropdown d-flex align-items-center">
            <Dropdown className="form-control border-0 style-1 h-auto">
              <Dropdown.Toggle
                as="div"
                className="fs-16 mb-2 mt-2 font-w500 text-primary justify-content-between d-flex align-items-center i-false"
              >
                {courseList}
                <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
                <Dropdown.Item
                  className="text-primary"
                  onClick={() => {
                    setCourseIdList(0);
                    setCourseIdfilter(0);
                    setCourseList("Choose Course");
                    setSpecializationId(0);
                    setSpecialization("Choose Specialization");
                  }}
                >
                  Choose Course
                </Dropdown.Item>
                {courses.map((course) => (
                  <Dropdown.Item
                    className="text-primary"
                    onClick={() => {
                      setCourseIdList(course.filter_id);
                      setCourseIdfilter(course.college_course_id);
                      setCourseList(course.name);
                      setSpecializationId(0);
                      setSpecialization("Choose Specialization");
                    }}
                  >
                    {course.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-xl-4 col-xxl-4 col-lg-4 col-sm-4 col-12 search-dropdown d-flex align-items-center">
            <Dropdown className="form-control border-0 style-1 h-auto">
              <Dropdown.Toggle
                as="div"
                className="fs-16 mb-2 mt-2 font-w500 text-primary justify-content-between d-flex align-items-center i-false"
              >
                {specialization}
                <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
                <Dropdown.Item
                  className="text-primary"
                  onClick={() => {
                    setSpecializationId(null);
                    setSpecialization("Choose Specialization");
                  }}
                >
                  Choose Specialization
                </Dropdown.Item>

                {specializationData && specializationData?.length > 0 ? (
                  specializationData?.map((specialization) => (
                    <Dropdown.Item
                      className="text-primary"
                      onClick={() => {
                        setSpecializationId(
                          specialization.specilization_master_id
                        );
                        setSpecialization(specialization.name);
                      }}
                    >
                      {specialization.name}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item
                    className="text-primary"
                    onClick={() => {
                      setSpecializationId(null);
                      setSpecialization("All");
                    }}
                  >
                    All
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-xl-4 col-xxl-4 col-lg-4 col-sm-4 col-12 d-flex align-items-center">
            <Dropdown className="form-control border-0  h-auto">
              <Dropdown.Toggle
                as="div"
                className="fs-16 mb-2 mt-2 font-w500 text-primary justify-content-between d-flex align-items-center i-false"
              >
                {passoutYear}
                <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
                <Dropdown.Item
                  className=" text-primary"
                  onClick={() => setPassoutYear("Passout Year")}
                >
                  Passout Year
                </Dropdown.Item>
                <Dropdown.Item
                  className=" text-primary"
                  onClick={() => setPassoutYear(currentYear - 1)}
                >
                  {currentYear - 1}
                </Dropdown.Item>
                <Dropdown.Item
                  className=" text-primary"
                  onClick={() => setPassoutYear(currentYear)}
                >
                  {currentYear}
                </Dropdown.Item>
                <Dropdown.Item
                  className=" text-primary"
                  onClick={() => setPassoutYear(currentYear + 1)}
                >
                  {currentYear + 1}
                </Dropdown.Item>
                <Dropdown.Item
                  className=" text-primary"
                  onClick={() => setPassoutYear(currentYear + 2)}
                >
                  {currentYear + 2}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {/* Placement Statistics */}
      <div className="row">
        <div className="col-xl-12 mt-4">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="row shapreter-row">
                    <div className="col-xl-2 col-lg-2 col-sm-4 col-6">
                      <div className="static-icon">
                        <span>
                          <i className="fas fa-users"></i>{" "}
                          {/* Icon for Total Students */}
                        </span>
                        <h3 className="count">
                          <CountUp
                            end={
                              statistics?.total_students
                                ? statistics?.total_students
                                : 0
                            }
                            duration={2}
                          />
                        </h3>
                        <span className="fs-14" style={{ fontWeight: "500" }}>
                          Total Students
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-sm-4 col-6">
                      <div className="static-icon">
                        <span>
                          <i className="fas fa-check-circle"></i>{" "}
                          {/* Icon for Total Placed Students */}
                        </span>
                        <h3 className="count">
                          <CountUp
                            end={
                              statistics?.total_placed_students
                                ? statistics?.total_placed_students
                                : 0
                            }
                            duration={2}
                          />
                        </h3>
                        <span className="fs-14" style={{ fontWeight: "500" }}>
                          Total Placed Students
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-sm-4 col-6">
                      <div className="static-icon">
                        <span>
                          {/* Icon for Total Pending Placement */}
                          <i className="fas fa-user-times"></i>{" "}
                        </span>
                        <h3 className="count">
                          <CountUp
                            end={
                              statistics?.total_unplaced_students
                                ? statistics?.total_unplaced_students
                                : 0
                            }
                            duration={2}
                          />
                        </h3>
                        <span className="fs-14" style={{ fontWeight: "500" }}>
                          Total Pending Placement
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-sm-4 col-6">
                      <div className="static-icon">
                        <span>
                          <i className="fas fa-rupee-sign"></i>{" "}
                          {/* Icon for Highest Package */}
                        </span>
                        <h3 className="count">
                          <CountUp
                            end={
                              statistics?.highest_package
                                ? statistics?.highest_package >= 10000000 // 1 crore in paise
                                  ? (
                                      statistics?.highest_package / 10000000
                                    ).toFixed(2) // Convert to crore
                                  : (
                                      statistics?.highest_package / 100000
                                    ).toFixed(2) // Convert to lakh
                                : "0"
                            }
                            duration={2}
                          />{" "}
                          {statistics?.highest_package >= 10000000
                            ? "Cr"
                            : "LPA"}
                        </h3>
                        <span className="fs-14" style={{ fontWeight: "500" }}>
                          Highest Package
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-sm-4 col-6">
                      <div className="static-icon">
                        <span>
                          <i className="fas fa-briefcase"></i>{" "}
                          {/* Icon for Internship Received */}
                        </span>
                        <h3 className="count">
                          <CountUp
                            end={
                              statistics?.total_internship_received_students
                                ? statistics?.total_internship_received_students
                                : 0
                            }
                            duration={2}
                          />
                        </h3>
                        <span className="fs-14" style={{ fontWeight: "500" }}>
                          Internship Received
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-sm-4 col-6">
                      <div className="static-icon">
                        <span>
                          <i className="fas fa-clock"></i>{" "}
                          {/* Icon for Internship Pending */}
                        </span>
                        <h3 className="count">
                          <CountUp
                            end={
                              statistics?.total_internship_notreceived_students
                                ? statistics?.total_internship_notreceived_students
                                : 4
                            }
                            duration={2}
                          />
                        </h3>
                        <span className="fs-14" style={{ fontWeight: "500" }}>
                          Internship Pending
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <ProfileStrength stats={statistics} />
            </div>
            <div className="col-xl-6">
              <CanvasChartTab stats={placementCharts} />
              {/* <NetworkSection /> */}
            </div>
            <div className="col-xl-12">
              <VacanciesStatsTab stats={jobStatistics} />
            </div>
            {/* <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-12"></div>
              </div>
            </div> */}
            <div className="col-xl-7">
              <FeaturedCompanies companies={statistics} />
            </div>
            <div className="col-xl-5">
              <TopRoles companies={statistics} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Statistics;
