const initialState = {
  college_id: localStorage.getItem("college_id") || null, // Load from localStorage
};

const CollegeidReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COLLEGEID":
      localStorage.setItem("college_id", action.payload);
      return { ...state, college_id: action.payload };
    case "CLEAR_COLLEGEID":
      return { ...state, college_id: null };
    default:
      return state;
  }
};

export default CollegeidReducer;
