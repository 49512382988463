import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { API_URL } from "../../../config";

function JobOfferDetails() {
  const [jobOffer, setJobOffer] = useState({});
  const [message, setErrMessage] = useState("");
  const [loading, setLoading] = useState(true); // loading state
  const { offerId } = useParams();

  useEffect(() => {
    const fetchJobOffer = async () => {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const authToken = userDetails?.data?.auth_token;

      try {
        setLoading(true); // Start loading
        const params = new URLSearchParams();
        params.append("offer_id", offerId);

        const response = await axios.post(
          `${API_URL}/api/freshment_portal_apis/PlaceOffer/get_job_offer_detail`,
          params,
          {
            headers: {
              Authorization: authToken,
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          }
        );
        setJobOffer(response.data.data);
        setErrMessage(response.data.message);
        console.log(response.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchJobOffer();
  }, [offerId]);

  return (
    <>
      <div>
        <div className="d-flex align-items-center mb-4">
          <h4 className="fs-20 font-w600 mb-0 me-auto">Job Offer Details</h4>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              {loading ? (
                // Display loading spinner or message
                <div className="card-body text-center">
                  <div className="text-center my-4">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : jobOffer ? (
                <>
                  <div className="card-body">
                    <div className="p-2">
                      <div className="row">
                        {/* Personal Information */}
                        <div className="col-xl-6 col-md-6">
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Student Name:{" "}
                            </span>
                            <span className="font-w400">
                              {jobOffer.full_name}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Student Email:{" "}
                            </span>
                            <span className="font-w400">{jobOffer.email}</span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Student Phone Number:{" "}
                            </span>
                            <span className="font-w400">
                              {jobOffer.phone_number}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">Course: </span>
                            <span className="font-w400">
                              {jobOffer.course_name}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Specialization:{" "}
                            </span>
                            <span className="font-w400">
                              {jobOffer.specialization_name}{" "}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Passout Year:{" "}
                            </span>
                            <span className="font-w400">
                              {jobOffer.passout_year}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">Offer Date: </span>
                            <span className="font-w400">
                              {jobOffer.date_offered || "N/A"}
                            </span>
                          </p>
                        </div>

                        {/* Company and Job Information */}
                        <div className="col-xl-6 col-md-6">
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">Offer ID: </span>
                            <span className="font-w400">
                              #{jobOffer.job_offer_id || "N/A"}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Company Name:{" "}
                            </span>
                            <span className="font-w400">
                              {jobOffer.company_name || "N/A"}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Company Address:{" "}
                            </span>
                            <span className="font-w400">
                              {jobOffer.company_address || "N/A"}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Company Email:{" "}
                            </span>
                            <span className="font-w400">
                              {jobOffer.company_email || "N/A"}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">Position: </span>
                            <span className="font-w400">
                              {jobOffer.position || "N/A"}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">Package: </span>
                            <span className="font-w400">
                              {jobOffer.package || "N/A"}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">Accepted: </span>
                            <span className="font-w400">
                              {jobOffer.acceptance == 1 ? "Yes" : "No"}
                            </span>
                          </p>
                          {/* <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Joining Date:{" "}
                            </span>
                            <span className="font-w400">
                              {jobOffer.joining_date || "N/A"}
                            </span>
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="card-body pt-0">
                  <p className="text-center mt-5 fs-20">{message}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobOfferDetails;
