import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../../table/FilteringTable/filtering.css";
import { Dropdown } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { API_URL } from "../../../../config";
const ApplicationDetails = () => {
  const { type, id } = useParams();
  const [studentList, setStudentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [studentsPerPage, setStudentsPerPage] = useState(10); // Can be updated with dropdown
  const [loading, setLoading] = useState(false);
  const [totalstudents, setTotalstudents] = useState(0);
  const [jobType, setJobType] = useState(
    type == "student" ? "Select Activity" : "like"
  );
  const [jobName, setJobName] = useState(
    type == "student" ? "Select Activity" : "Job Interested"
  );
  const maxPagesToShow = 10;

  const fetchStudentList = async (id) => {
    // const payload = {
    //   offset: currentPage * studentsPerPage,
    //   limit: studentsPerPage,
    // };
    setLoading(true);
    const params = {
      offset: currentPage * studentsPerPage,
      limit: studentsPerPage,
      [type == "student" ? "student_id" : "job_id"]: id,
    };

    if (jobType !== null && jobType !== "Select Activity") {
      params.filter_by_activity = jobType;
    }
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    console.log(params);
    try {
      const response = await axios.get(
        `${API_URL}/api/freshment_portal_apis/Jobs/get_job_analysis_student`,
        {
          params,
          headers: {
            Authorization: authToken,
          },
        }
      );

      let jsonData;
      if (typeof response.data === "string") {
        let cleanedData = response.data.replace(/\[\]$/, "").trim();
        jsonData = JSON.parse(cleanedData);
      } else {
        jsonData = response.data;
      }
      const { data, totalRecords } = jsonData;
      setStudentData(data || []);
      console.log(data);
      setTotalstudents(totalRecords);
      // setCurrentPage(0); // Reset the current page
    } catch (err) {
      console.error("Error fetching student list:", err);
    } finally {
      setLoading(false);
    }
  };

  const totalPages = Math.ceil(totalstudents / studentsPerPage);

  const indexOfLastStudent = (currentPage + 1) * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  // const currentStudents = studentList?.slice(
  //   indexOfFirstStudent,
  //   indexOfLastStudent > totalStudents ? totalStudents : indexOfLastStudent
  // );

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleStudentsPerPageChange = (event) => {
    setStudentsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to the first page when page size changes
  };

  //   useEffect(() => {
  //   fetchStudentList(id);
  // }, [currentPage]);

  const renderPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(0, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages - 1, startPage + maxPagesToShow - 1);

    if (startPage > 0)
      pages.push(
        <span key="start-ellipsis" style={{ fontSize: 24 }}>
          ...
        </span>
      );

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Link
          key={i}
          to="#"
          className={`paginate_button ${i === currentPage ? "bg-white" : ""}`}
          onClick={() => handlePageChange(i)}
        >
          {i + 1}
        </Link>
      );
    }

    if (endPage < totalPages - 1)
      pages.push(
        <span key="end-ellipsis" style={{ fontSize: 24 }}>
          ...
        </span>
      );

    return pages;
  };

  useEffect(() => {
    if (id) {
      // Check if 'id' is not null or undefined
      fetchStudentList(id); // Call fetchStudentList with 'id'
    }
  }, [id, jobType, currentPage]); // 'jobType' is included in dependencies but not necessarily used directly

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h4 className="fs-22 mb-0">Activity Logs</h4>
        <Dropdown
          className="form-control border-0"
          style={{ height: 45, width: "20%" }}
        >
          <Dropdown.Toggle
            as="div"
            className=" mt-1 fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false"
          >
            {jobName}
            <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
            <Dropdown.Item
              className="text-primary"
              onClick={() => {
                setJobType("Select Activity");
                setJobName("Select Activity");
              }}
            >
              Select Activity
            </Dropdown.Item>
            <Dropdown.Item
              className="text-primary"
              onClick={() => {
                setJobType("view");
                setJobName("Job Visited");
              }}
            >
              Job Visited
            </Dropdown.Item>

            <Dropdown.Item
              className="text-primary"
              onClick={() => {
                setJobType("share");
                setJobName("Job Shared");
              }}
            >
              Job Shared
            </Dropdown.Item>
            <Dropdown.Item
              className="text-primary"
              onClick={() => {
                setJobType("like");
                setJobName("Job Interested");
              }}
            >
              Job Interested
            </Dropdown.Item>
            <Dropdown.Item
              className="text-primary"
              onClick={() => {
                setJobType("SendResume");
                setJobName("Resume Sent");
              }}
            >
              Resume Sent
            </Dropdown.Item>
            <Dropdown.Item
              className="text-primary"
              onClick={() => {
                setJobType("CallHR");
                setJobName("Contacted HR");
              }}
            >
              Contacted HR
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : studentList && studentList.length > 0 ? (
        <div
          className="table-responsive dataTables_wrapper"
          id="application-data"
        >
          <table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
            <thead>
              <tr role="row">
                <th className="sorting_asc">Activity</th>
                <th className="sorting_asc text-center">Message</th>
                <th className="sorting_asc">Date & Time</th>
              </tr>
            </thead>
            <tbody>
              {studentList?.map((student) => (
                <tr role="row" key={student.user_id} className="odd">
                  <td>{student.analysis_type}</td>
                  <td>{student.message}</td>
                  <td>{student.created_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-sm-flex text-center justify-content-between align-items-center">
            <div className="dataTables_info" id="example5_info">
              Showing {indexOfFirstStudent + 1} to{" "}
              {Math.min(indexOfLastStudent, totalstudents)} of {totalstudents}{" "}
              entries
            </div>
            <div className="dataTables_paginate paging_simple_numbers">
              <Link
                to="#"
                className={`paginate_button previous ${
                  currentPage === 0 ? "disabled" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </Link>
              <span>{renderPageNumbers()}</span>
              <Link
                to="#"
                className={`paginate_button next ${
                  currentPage + 1 >= totalPages ? "disabled" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </Link>
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="mr-2 mb-0" for="studentsPerPage">
                Show
              </label>
              <select
                id="studentsPerPage"
                style={{
                  marginRight: "10px",
                  marginLeft: "10px",
                  backgroundColor: "white",
                  borderRadius: 0,
                  border: "1px solid #cccccc",
                }}
                value={studentsPerPage}
                onChange={handleStudentsPerPageChange}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <label class="ml-2 mb-0">entries per page</label>
            </div>
          </div>
        </div>
      ) : (
        <div>No Activity Logs Found</div>
      )}
    </>
  );
};

export default ApplicationDetails;
