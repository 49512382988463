import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import logo2 from "../../images/fpass_logo.png";
import backArrow from "../../images/back_arrow.png";
import { API_URL } from "../../config";
import freshmentlogo from "../../images/freshmentLogo1.png";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const history = useHistory();

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!email) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your email.",
      });
      return;
    }

    // Show loading indicator
    Swal.fire({
      title: "Processing...",
      text: "Please Wait",
      allowOutsideClick: false, // Prevent closing on outside click
      didOpen: () => {
        Swal.showLoading(); // Start loader animation
      },
    });

    try {
      const res = await axios.post(
        `${API_URL}/api/freshment_portal_apis/College/forgot_password?college_email=${email}`
      );

      Swal.close(); // Close the loader

      if (res.data.success) {
        Swal.fire({
          icon: "success",
          title: "Varification Code Sent!",
          text: res.data.message,
        }).then(() => {
          history.push("/reset-password");
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.data.message,
        });
      }
    } catch (error) {
      Swal.close(); // Ensure the loader is closed on error
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again later.",
      });
    }
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-left mb-3">
                      <Link to="/login">
                        <img src={backArrow} alt="" />
                      </Link>
                    </div>
                    <div className="text-center mb-3">
                      <img
                        style={{ height: "100%", width: "350px" }}
                        src={freshmentlogo}
                        alt=""
                      />
                    </div>
                    <div className="text-center mb-3">
                      <img src={logo2} alt="" />
                    </div>
                    <h4 className="text-center mb-4">Forgot Password</h4>
                    <h6 className="text-center mb-4">
                      Enter your email and we’ll send you a link to reset your{" "}
                      <br />
                      password.
                    </h6>
                    <form onSubmit={onSubmit}>
                      <div className="form-group">
                        <label>
                          <strong>Email</strong>
                        </label>
                        <input
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="text-center mt-4">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
