import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// image
import logo from "../../images/freshment.png";
import logo2 from "../../images/fpass_logo.png";
import backArrow from "../../images/back_arrow.png";
import { useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../config";
import { useParams } from "react-router-dom";
import freshmentlogo from "../../images/freshmentLogo1.png";
const ChangePassword = () => {
  const { collegeid } = useParams();
  console.log(collegeid);
  const [oldpass, setoldPass] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  console.log(oldpass, password, confirmPassword);
  const history = useHistory();
  const onSubmitData = (e) => {
    e.preventDefault();
    const payload = {
      old_password: oldpass,
      password: password,
      confirmpassword: confirmPassword,
      college_id: collegeid,
    };
    const params = new URLSearchParams();
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;

    params.append("old_password", payload.old_password);
    params.append("password", payload.password);
    params.append("confirmpassword", payload.confirmpassword);
    params.append("college_id", payload.college_id);
    axios
      .post(
        `${API_URL}/api/freshment_portal_apis/College/change_password `,
        params,
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          Swal.fire({
            icon: "success",
            title: "Password Change",
            text: res.data.message,
          }).then(() => {
            history.push("/dashboard");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.data.message,
          });
        }
      });
  };
  return (
    <div
      className="d-flex justify-content-center align-items-center h-100 "
      // style={{ width: "0%" }}
    >
      <div style={{ width: "50%" }}>
        {" "}
        <div className="row mt-5 mb-5 justify-content-center h-100 align-items-center">
          <div className="col-md-12">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-left mb-3">
                      <Link to="/login">
                        <img src={backArrow} alt="" />
                      </Link>
                    </div>
                    <div className="text-center mb-3">
                      <img
                        style={{ height: "100%", width: "350px" }}
                        src={freshmentlogo}
                        alt=""
                      />
                    </div>
                    <div className="text-center mb-3">
                      <img src={logo2} alt="" />
                    </div>
                    <div>
                      <br />
                    </div>
                    <h3 className="text-center mb-4 ">Change Password</h3>
                    {/* <h6 className="text-center mb-4 ">Enter Your OTP Here</h6> */}
                    <form>
                      <div className="form-group">
                        <label className="">
                          <strong>Enter Old Password</strong>
                        </label>
                        <input
                          onChange={(e) => setoldPass(e.target.value)}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label className="">
                          <strong>Enter New Password</strong>
                        </label>
                        <input
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label className="">
                          <strong>Enter Confirm Password</strong>
                        </label>
                        <input
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          type="password"
                          className="form-control"
                        />
                      </div>
                      <div>
                        <br />
                        <br />
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          onClick={onSubmitData}
                          className="btn btn-primary"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
