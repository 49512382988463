import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../../config";
import { useParams } from "react-router-dom/cjs/react-router-dom";

function InternshipOfferDetails() {
  const [InternshipOffer, setInternshipOffer] = useState({});
  const [loading, setLoading] = useState(true); // loading state
  const [message, setMessage] = useState("");
  const { offerId } = useParams();

  useEffect(() => {
    const fetchInternshipOffer = async () => {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const authToken = userDetails?.data?.auth_token;
      try {
        setLoading(true); // Start loading
        const params = new URLSearchParams();
        params.append("offer_id", offerId);

        const response = await axios.post(
          `${API_URL}/api/freshment_portal_apis/PlaceOffer/get_internship_offer_detail`,
          params,
          {
            headers: {
              Authorization: authToken,
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          }
        );
        setInternshipOffer(response.data.data);
        setMessage(response.data.message);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchInternshipOffer();
  }, [offerId]);

  return (
    <>
      <div>
        <div className="d-flex align-items-center mb-4">
          <h4 className="fs-20 font-w600 mb-0 me-auto">
            Internship Offer Details
          </h4>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              {loading ? (
                // Display loading spinner or message
                <div className="card-body text-center">
                  <div className="text-center my-4">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : InternshipOffer ? (
                <>
                  <div className="card-body">
                    <div className="p-2">
                      <div className="row">
                        {/* Personal Information */}
                        <div className="col-xl-6 col-md-6">
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">Full Name: </span>
                            <span className="font-w400">
                              {InternshipOffer.full_name}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">Email: </span>
                            <span className="font-w400">
                              {InternshipOffer.email}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Phone Number:{" "}
                            </span>
                            <span className="font-w400">
                              {InternshipOffer.phone_number}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">Course: </span>
                            <span className="font-w400">
                              {InternshipOffer.course_name}
                              {InternshipOffer.specialization_name
                                ? `( ${InternshipOffer.specialization_name} )`
                                : null}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">Position: </span>
                            <span className="font-w400">
                              {InternshipOffer.position || "N/A"}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Passout Year:{" "}
                            </span>
                            <span className="font-w400">
                              {InternshipOffer.passout_year}
                            </span>
                          </p>
                        </div>

                        {/* Company and Job Information */}
                        <div className="col-xl-6 col-md-6">
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Company Name:{" "}
                            </span>
                            <span className="font-w400">
                              {InternshipOffer.company_name || "N/A"}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Company Email:{" "}
                            </span>
                            <span className="font-w400">
                              {InternshipOffer.company_email || "N/A"}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Company Address:{" "}
                            </span>
                            <span className="font-w400">
                              {InternshipOffer.company_address || "N/A"}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">Package: </span>
                            <span className="font-w400">
                              {InternshipOffer.package || "N/A"}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Date Offered:{" "}
                            </span>
                            <span className="font-w400">
                              {InternshipOffer.date_offered}
                            </span>
                          </p>
                          <p className="font-w600 d-flex">
                            <span className="custom-label-2">
                              Joining Date:{" "}
                            </span>
                            <span className="font-w400">
                              {InternshipOffer.joining_date || "N/A"}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="card-body pt-0">
                  <p className="text-center mt-5 fs-20">{message}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InternshipOfferDetails;
