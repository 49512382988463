import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

//Images
import pic1 from "./../../../../images/comapny/1.png";
import pic2 from "./../../../../images/comapny/2.png";
import pic3 from "./../../../../images/comapny/3.png";
import pic4 from "./../../../../images/comapny/4.png";
import pic5 from "./../../../../images/comapny/5.png";
import pic6 from "./../../../../images/comapny/6.png";

const Companyblog = [
  { image: pic1, title: "Kleon Team" },
  { image: pic2, title: "Ziro Studios Inc." },
  { image: pic3, title: "Qerza" },
  { image: pic4, title: "Kripton Studios" },
  { image: pic5, title: "Omah Ku Inc." },
  { image: pic6, title: "Ventic" },
  { image: pic4, title: "Sakola" },
  { image: pic2, title: "Uena Foods" },
];

const TopRoles = (props) => {
  const [companies, setCompanies] = useState([]);
  const [data, setData] = useState(Companyblog);

  useEffect(() => {
    // console.log("Props companies:", props?.companies);
    setCompanies(props?.companies || []);
  }, [props.companies]);

  // useEffect(() => {
  //   console.log(companies);
  // }, [companies]);

  return (
    <>
      <div className="card">
        <div className="card-header border-0 pb-0">
          <h4 className="fs-20 mb-1" style={{ height: "60px" }}>
            Trending Job Roles (Most In-Demand Job Profiles)
          </h4>
        </div>
        <div className="card-body pt-3 loadmore-content">
          <div className="row" id="FeaturedCompaniesContent">
            {companies?.max_hired_by_job_roles &&
            companies?.max_hired_by_job_roles.length > 0 ? (
              companies?.max_hired_by_job_roles?.map((item, index) => (
                <div
                  className={`col-xl-12 col-sm-12 ${index == 0 ? "" : "mt-4"}`}
                  key={index}
                >
                  <div className="d-flex" style={{ alignItems: "center" }}>
                    {data[index] && (
                      <img src={data[index].image} alt={item?.position} />
                    )}
                    <div className="ms-3 featured">
                      <h4 className="fs-20 mb-1">{item?.position}</h4>
                      <span>Total Job Offers : {item?.hire_count}</span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div> No Job Roles Found </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default TopRoles;
