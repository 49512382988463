import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

class CanvasChart2 extends Component {
  render() {
    // console.log(this.props.chartData);
    var activityData = this?.props?.chartData?.map((item) =>
      parseInt(item.placement_count)
    );
    const data = {
      labels:
        this.props.dataActive == 0
          ? [
              "Day 1",
              "Day 2",
              "Day 3",
              "Day 4",
              "Day 5",
              "Day 6",
              "Day 7",
              "Day 8",
              "Day 9",
              "Day 10",
              "Day 11",
              "Day 12",
            ]
          : this.props.dataActive == 1
          ? [
              "Week 1",
              "Week 2",
              "Week 3",
              "Week 4",
              "Week 5",
              "Week 6",
              "Week 7",
              "Week 8",
              "Week 9",
              "Week 10",
              "Week 11",
              "Week 12",
            ]
          : [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "June",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
      datasets: [
        {
          label: "Placed Students",
          backgroundColor: "#F73A0B",
          borderColor: "var(--primary)",
          data: activityData,
          borderWidth: 0,
          barThickness: "flex",
          minBarLength: 10,
          barPercentage: 0.3,
        },
      ],
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,

      plugins: {
        legend: false,
      },
      scales: {
        y: {
          grid: {
            color: "rgba(255,255,255,0.2)",
            drawBorder: true,
          },
          ticks: {
            fontColor: "#6E6E6E",
            max: 60,
            min: 0,
            stepSize: 20,
          },
        },
        x: {
          grid: {
            display: false,
            zeroLineColor: "transparent",
          },
          ticks: {
            stepSize: 20,
            fontColor: "#6E6E6E",
            fontFamily: "Nunito, sans-serif",
          },
        },
      },
      tooltips: {
        mode: "index",
        intersect: false,
        titleFontColor: "#888",
        bodyFontColor: "#555",
        titleFontSize: 12,
        bodyFontSize: 15,
        backgroundColor: "rgba(255,255,255,1)",
        displayColors: true,
        xPadding: 10,
        yPadding: 7,
        borderColor: "rgba(220, 220, 220, 1)",
        borderWidth: 1,
        caretSize: 6,
        caretPadding: 10,
      },
    };

    return (
      <div style={{ minHeight: "200px" }}>
        <Bar
          data={data}
          width={this.props.width ? this.props.width : 433}
          height={this.props.height ? this.props.height : 200}
          options={options}
        />
      </div>
    );
  }
}

export default CanvasChart2;
