import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { useEffect, useState } from "react";

const ApexPieChart2 = loadable(() =>
  pMinDelay(import("./ApexPieChart2"), 1000)
);

const ProfileStrength = (props) => {
  const [stats, setStats] = useState([]);

  const placedPercentage = stats?.total_students
    ? ((stats.total_placed_students / stats.total_students) * 100).toFixed(2)
    : 0;
  const unplacedPercentage = stats?.total_students
    ? ((stats.total_unplaced_students / stats.total_students) * 100).toFixed(2)
    : 0;

  useEffect(() => {
    setStats(props?.stats);
  }, [props?.stats]);
  return (
    <>
      <div className="card">
        <div className="card-header border-0 pb-0">
          <h4 className="fs-20 font-w600">Placement Strength</h4>
        </div>
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-xl-6 col-sm-6">
              <div className="progress default-progress">
                <div
                  className="progress-bar bg-vigit progress-animated"
                  style={{ width: "100%", height: "13px" }}
                  role="progressbar"
                >
                  <span className="sr-only">100% Complete</span>
                </div>
              </div>
              <div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
                <span className="fs-14 font-w500">Total Students</span>
                <span className="fs-16">
                  <span className="text-black pe-2">
                    {stats?.total_students}
                  </span>
                </span>
              </div>
              <div className="progress default-progress">
                <div
                  className="progress-bar bg-contact progress-animated"
                  style={{
                    width: `${placedPercentage}%`,
                    height: "13px",
                  }}
                  role="progressbar"
                >
                  <span className="sr-only">{placedPercentage}% Complete</span>
                </div>
              </div>
              <div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
                <span className="fs-14 font-w500">Placed Students</span>
                <span className="fs-16">
                  <span className="text-black pe-2"></span>
                  {stats?.total_placed_students}({placedPercentage})%
                </span>
              </div>
              <div className="progress default-progress">
                <div
                  className="progress-bar bg-follow progress-animated"
                  style={{
                    width: `${unplacedPercentage}%`,
                    height: "13px",
                  }}
                  role="progressbar"
                >
                  <span className="sr-only">
                    {unplacedPercentage}% Complete
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
                <span className="fs-14 font-w500">Pending Students</span>
                <span className="fs-16">
                  <span className="text-black pe-2"></span>
                  {stats?.total_unplaced_students}({unplacedPercentage})%
                </span>
              </div>
            </div>
            <div className="col-xl-6 col-sm-6">
              <div id="pieChart3">
                <ApexPieChart2
                  total={props?.stats?.total_students}
                  placed={props?.stats?.total_placed_students}
                  unplaced={props?.stats?.total_unplaced_students}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfileStrength;
