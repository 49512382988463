import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

//Import
import JobsTabBlog from "../Jobick/Jobs/JobsTabBlog/JobsTabBlog";
import JobPreview from "../Jobick/Jobs/JobPreview";
import axios from "axios";
import { Button } from "react-bootstrap";
import { data } from "jquery";
import { useDispatch } from "react-redux";
import { fetchCourses } from "../../../store/actions/CourseAction";
import { useSelector } from "react-redux";
import { setCourses } from "../../../store/actions/CourseAction";
import { setJobCategory } from "../../../store/actions/CourseAction";
const JobLists = () => {
  const [search, setSearch] = useState("");
  const [control4, setControl4] = useState("Choose Course");
  const [control6, setControl6] = useState("Job Type");
  const [control5, setControl5] = useState("Passout Batch");
  const [control7, setControl7] = useState(0);
  const [courseId, setCourseId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [selectedcourse, setCourse] = useState("");
  const [selectedtype, setType] = useState("");
  const [selectedbatch, setBatch] = useState("");
  const [selectedCategory, setCategory] = useState(0);
  const [cid, setCid] = useState("");
  const courses = useSelector((state) => state.courses.courses);
  const categories = useSelector((state) => state.job_category.job_category);
  console.log(categories);
  const currentYear = new Date().getFullYear();
  const dispatch = useDispatch();
  useEffect(() => {
    const storedCourses = localStorage.getItem("courses");
    if (storedCourses) {
      dispatch(setCourses(JSON.parse(storedCourses)));
    }
    const storedCategory = localStorage.getItem("job_category");
    if (storedCategory) {
      dispatch(setJobCategory(JSON.parse(storedCategory)));
    }
  }, [dispatch]);

  useEffect(() => {
    console.log(control4, courseId);
    setCourse(control4);
    setType(control6);
    setBatch(control5);
    setCid(courseId);
    setCategory(control7);
  }, [control4, control6, control5, control7, courseId]);
  const handleSearch = () => {
    setSearch(inputValue);
    setCourse(control4);
    setType(control6);
    setBatch(control5);
    setCid(courseId);
  };

  const resetFilter = () => {
    setControl4("Choose Course");
    setControl6("Job Type");
    setControl5("Passout Batch");
    setCourseId(null);
    setSearch("");
    setCourse("Choose Course");
    setType("Job Type");
    setBatch("Passout Batch");
    setCid(null);
    setInputValue("");
    setCategory(0);
    setControl7(0);
  };

  return (
    <>
      <div className="d-flex align-items-center flex-wrap search-job bg-white px-0 mb-4">
        <div className="col-xl-2 col-xxl-2 col-lg-2 col-sm-4 col-12 search-dropdown d-flex align-items-center">
          <Dropdown className="form-control border-0 style-1 h-auto">
            <Dropdown.Toggle
              as="div"
              className="fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false"
            >
              {control4}
              <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
              <Dropdown.Item
                className="text-primary"
                onClick={() => {
                  setControl4("Choose Course");
                  setCourseId(null);
                }}
              >
                Choose Course
              </Dropdown.Item>
              {courses?.map((course) => (
                <Dropdown.Item
                  className="text-primary"
                  onClick={() => {
                    setControl4(course.name);
                    setCourseId(course.filter_id);
                  }}
                >
                  {course.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-xl-2 col-xxl-2 col-lg-2 col-sm-4 col-12 search-dropdown d-flex align-items-center">
          <Dropdown className="form-control border-0  style-1 h-auto">
            <Dropdown.Toggle
              as="div"
              className="fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false"
            >
              {control6}
              <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
              <Dropdown.Item
                className=" text-primary"
                onClick={() => setControl6("Job Type")}
              >
                Job Type
              </Dropdown.Item>
              <Dropdown.Item
                className=" text-primary"
                onClick={() => setControl6("Campus Invite")}
              >
                Campus Invite
              </Dropdown.Item>
              <Dropdown.Item
                className=" text-primary"
                onClick={() => setControl6("Internship")}
              >
                Internship
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-xl-2 col-xxl-2 col-lg-2 col-sm-4 col-12 search-dropdown d-flex align-items-center">
          <Dropdown className="form-control border-0  style-1 h-auto">
            <Dropdown.Toggle
              as="div"
              className="fs-16 font-w500 text-primary justify-content-between d-flex align-items-center i-false"
            >
              {control5}
              <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
              <Dropdown.Item
                className=" text-primary"
                onClick={() => setControl5("Passout Batch")}
              >
                Passout Batch
              </Dropdown.Item>
              <Dropdown.Item
                className=" text-primary"
                onClick={() => setControl5(currentYear - 1)}
              >
                {currentYear - 1}
              </Dropdown.Item>
              <Dropdown.Item
                className=" text-primary"
                onClick={() => setControl5(currentYear)}
              >
                {currentYear}
              </Dropdown.Item>
              <Dropdown.Item
                className=" text-primary"
                onClick={() => setControl5(currentYear + 1)}
              >
                {currentYear + 1}
              </Dropdown.Item>
              <Dropdown.Item
                className=" text-primary"
                onClick={() => setControl5(currentYear + 2)}
              >
                {currentYear + 2}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-xl-5 col-xxl-5 col-lg-5 col-12 d-md-flex job-title-search pe-0">
          <div className="input-group search-area">
            <input
              type="text"
              value={inputValue}
              className="form-control h-auto"
              placeholder="search job title here..."
              onChange={(e) => setInputValue(e.target.value)}
            />
            <span className="input-group-text">
              <Button
                onClick={handleSearch}
                className="btn btn-primary btn-rounded"
              >
                Search<i className="flaticon-381-search-2 ms-2"></i>
              </Button>
            </span>
          </div>
        </div>
        <div className="col-xl-1 col-xxl-1 col-lg-1 col-12 d-flex align-items-center justify-content-lg-end justify-content-center">
          <button
            onClick={() => {
              resetFilter();
            }}
            className="btn btn-danger"
          >
            Reset
          </button>
        </div>
      </div>

      <div className="mb-4">
        <div
          className="d-flex align-items-center"
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            width: "fit-content",
          }}
        >
          <div className="btn-group" role="group" style={{ gap: 3 }}>
            <button
              className="btn btn-md"
              style={
                control7 === 0
                  ? {
                      backgroundColor: "#ffa755",
                      borderRadius: "10px",
                      color: "white",
                      fontWeight: "bold",
                    }
                  : { backgroundColor: "white", borderRadius: "10px" }
              }
              onClick={() => setControl7(0)}
            >
              All
            </button>
            {categories &&
              categories.length > 0 &&
              categories.map((category) => (
                <button
                  className="btn btn-md"
                  style={
                    control7 === category.filter_id
                      ? {
                          backgroundColor: "#ffa755",
                          borderRadius: "10px",
                          color: "white",
                          fontWeight: "bold",
                        }
                      : { backgroundColor: "white", borderRadius: "10px" }
                  }
                  onClick={() => setControl7(category.filter_id)}
                >
                  {category.category_name}
                </button>
              ))}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          {selectedcourse != "" &&
            selectedbatch != "" &&
            selectedtype != "" && (
              <JobsTabBlog
                course={selectedcourse}
                courseId={cid}
                batch={selectedbatch}
                type={selectedtype}
                search={search}
                category={selectedCategory}
              />
            )}
        </div>
      </div>
    </>
  );
};
export default JobLists;
