import { lazy, Suspense, useEffect, useState } from "react";

/// Components
import Index from "./jsx";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
// action
import { checkAutoLogin } from "./services/AuthService";
// import TwoFactor from "./jsx/pages/TwoFactor";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
/// Style
// import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { Redirect } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";
// Lazy-loaded components
const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});
const TwoFactor = lazy(() => import("./jsx/pages/TwoFactor"));
const ResetPass = lazy(() => import("./jsx/pages/ResetPassword"));
function App(props) {
  const [contactModal, setContactModal] = useState(false);
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState(false);
  useEffect(() => {
    checkAutoLogin(dispatch, props.history);
  }, [dispatch, props.history]);

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/page-register" component={SignUp} />
      <Route path="/page-forgot-password" component={ForgotPassword} />
      <Route path="/two-factor-auth" component={TwoFactor} />
      <Route path="/reset-password" component={ResetPass} />
      <Route path="*" render={() => <Redirect to="/login" />} />
    </Switch>
  );

  if (props.isAuthenticated) {
    return (
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Index />
        <div
          onClick={() => setContactModal(true)}
          style={{ cursor: "pointer" }}
          class="floating-button"
        >
          <div class="bg-gradient-btn shrink">
            <div style={{ paddingRight: "15px" }}>
              <svg
                id="Capa_1"
                enable-background="new 0 0 512 512"
                height="30"
                viewBox="0 0 512 512"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
              >
                <g>
                  <path d="m483.637 213.16c-10.222-6.317-21.601-7.603-31.637-6.615v-10.545c0-107.799-88.214-196-196-196-107.799 0-196 88.214-196 196v10.545c-10.036-.989-21.416.298-31.637 6.615-18.821 11.632-28.363 36.139-28.363 72.84 0 36.762 9.56 61.399 28.415 73.228 9.366 5.876 20.304 7.917 31.585 6.947v10.825c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15v-181c0-8.284-6.716-15-15-15h-44.315c7.608-84.529 78.839-151 165.315-151s157.707 66.471 165.315 151h-44.315c-8.284 0-15 6.716-15 15v181c0 8.284 6.716 15 15 15h45v15c0 24.813-20.187 45-45 45h-48.58c-6.192-17.458-22.865-30-42.42-30h-30c-24.813 0-45 20.187-45 45s20.187 45 45 45h30c19.555 0 36.228-12.542 42.42-30h48.58c41.355 0 75-33.645 75-75 0-18.271 0-28.969 0-40.826 11.282.97 22.221-1.072 31.585-6.947 18.855-11.828 28.415-36.465 28.415-73.227 0-36.701-9.542-61.208-28.363-72.84zm-423.637 122.821c-4.786.833-10.768.891-15.642-2.167-9.259-5.808-14.358-22.789-14.358-47.814 0-24.785 4.985-41.569 14.036-47.259 4.894-3.077 11.06-2.858 15.964-1.89zm60-124.981v151h-30v-151zm166 271h-30c-8.271 0-15-6.729-15-15s6.729-15 15-15h30c8.271 0 15 6.729 15 15s-6.729 15-15 15zm136-120h-30v-151h30zm45.749-28.253c-4.848 3.095-10.896 3.035-15.749 2.194v-99.089c4.904-.968 11.069-1.187 15.964 1.89 9.051 5.689 14.036 22.473 14.036 47.258 0 24.923-5.062 41.88-14.251 47.747z"></path>
                </g>
              </svg>
            </div>
            <span class="ml-2 text-white hide-text show-text">
              <span class="letter">S</span>
              <span class="letter">U</span>
              <span class="letter">P</span>
              <span class="letter">P</span>
              <span class="letter">O</span>
              <span class="letter">R</span>
              <span class="letter">T</span>
            </span>
          </div>
        </div>
        <Modal
          show={contactModal}
          onHide={() => setContactModal(false)}
          centered
        >
          <Modal.Body className="p-4 position-relative">
            <h3 className="text-center mb-4">How can we help?</h3>
            <Button
              onClick={() => setContactModal(false)}
              variant=""
              className="btn-close position-absolute"
              style={{ top: "1.2rem", right: "1rem" }}
            ></Button>
            <div className="d-block text-center align-items-center mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12em"
                height="12em"
                viewBox="0 0 28 28"
              >
                <path
                  fill="currentColor"
                  d="M14 19a2 2 0 0 1-1.839-1.212a8 8 0 0 1-.951-.288l-.017-.006A8 8 0 0 1 8.708 16a8 8 0 1 1 13.257-6.75c.039.413-.3.75-.715.75c-.414 0-.745-.337-.793-.749A6.5 6.5 0 1 0 11.496 16l.04.017q.3.123.616.217a2 2 0 0 1 3.785 1.266A2 2 0 0 1 14 19m-7-1.5h1.169a9.6 9.6 0 0 1-1.518-1.48A3 3 0 0 0 4 19v.715C4 23.433 8.21 26 14 26s10-2.708 10-6.285V19a3 3 0 0 0-3-3h-3.645a3.5 3.5 0 0 1 .11 1.5H21l.145.007A1.5 1.5 0 0 1 22.5 19v.715l-.005.161c-.14 2.52-3.569 4.624-8.495 4.624c-5.111 0-8.5-2.111-8.5-4.785V19l.007-.145A1.5 1.5 0 0 1 7 17.5M19 10a5 5 0 0 1-2.644 4.411A3.5 3.5 0 0 0 14 13.5a3.5 3.5 0 1 0 0-7a3.5 3.5 0 0 0 0 7c-.908 0-1.734.346-2.355.912a5 5 0 0 1-1.932-1.838A5 5 0 1 1 19 10"
                ></path>
              </svg>
            </div>

            <div className="d-flex align-items-center mb-3">
              <svg
                className="me-3"
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                >
                  <path
                    strokeDasharray={64}
                    strokeDashoffset={64}
                    d="M8 3c0.5 0 2.5 4.5 2.5 5c0 1 -1.5 2 -2 3c-0.5 1 0.5 2 1.5 3c0.39 0.39 2 2 3 1.5c1 -0.5 2 -2 3 -2c0.5 0 5 2 5 2.5c0 2 -1.5 3.5 -3 4c-1.5 0.5 -2.5 0.5 -4.5 0c-2 -0.5 -3.5 -1 -6 -3.5c-2.5 -2.5 -3 -4 -3.5 -6c-0.5 -2 -0.5 -3 0 -4.5c0.5 -1.5 2 -3 4 -3Z"
                  >
                    <animate
                      fill="freeze"
                      attributeName="stroke-dashoffset"
                      dur="0.6s"
                      values="64;0"
                    ></animate>
                    <animateTransform
                      id="lineMdPhoneCallLoop0"
                      fill="freeze"
                      attributeName="transform"
                      begin="0.6s;lineMdPhoneCallLoop0.begin+2.7s"
                      dur="0.5s"
                      type="rotate"
                      values="0 12 12;15 12 12;0 12 12;-12 12 12;0 12 12;12 12 12;0 12 12;-15 12 12;0 12 12"
                    ></animateTransform>
                  </path>
                  <path
                    strokeDasharray={4}
                    strokeDashoffset={4}
                    d="M15.76 8.28c-0.5 -0.51 -1.1 -0.93 -1.76 -1.24M15.76 8.28c0.49 0.49 0.9 1.08 1.2 1.72"
                  >
                    <animate
                      fill="freeze"
                      attributeName="stroke-dashoffset"
                      begin="lineMdPhoneCallLoop0.begin+0s"
                      dur="2.7s"
                      keyTimes="0;0.111;0.259;0.37;1"
                      values="4;0;0;4;4"
                    ></animate>
                  </path>
                  <path
                    strokeDasharray={6}
                    strokeDashoffset={6}
                    d="M18.67 5.35c-1 -1 -2.26 -1.73 -3.67 -2.1M18.67 5.35c0.99 1 1.72 2.25 2.08 3.65"
                  >
                    <animate
                      fill="freeze"
                      attributeName="stroke-dashoffset"
                      begin="lineMdPhoneCallLoop0.begin+0.2s"
                      dur="2.7s"
                      keyTimes="0;0.074;0.185;0.333;0.444;1"
                      values="6;6;0;0;6;6"
                    ></animate>
                  </path>
                </g>
              </svg>
              <span>
                Contact Us: <a href="tel:8355907853">8355907853</a>
              </span>
            </div>

            <div className="d-flex align-items-center mb-3">
              <svg
                className="me-3"
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  fillOpacity={0}
                  d="M12 11l-8 -5h16l-8 5Z"
                >
                  <animate
                    fill="freeze"
                    attributeName="fill-opacity"
                    begin="0.8s"
                    dur="0.15s"
                    values="0;0.3"
                  ></animate>
                </path>
                <g
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                >
                  <path
                    strokeDasharray={64}
                    strokeDashoffset={64}
                    d="M4 5h16c0.55 0 1 0.45 1 1v12c0 0.55 -0.45 1 -1 1h-16c-0.55 0 -1 -0.45 -1 -1v-12c0 -0.55 0.45 -1 1 -1Z"
                  >
                    <animate
                      fill="freeze"
                      attributeName="stroke-dashoffset"
                      dur="0.6s"
                      values="64;0"
                    ></animate>
                  </path>
                  <path
                    strokeDasharray={24}
                    strokeDashoffset={24}
                    d="M3 6.5l9 5.5l9 -5.5"
                  >
                    <animate
                      fill="freeze"
                      attributeName="stroke-dashoffset"
                      begin="0.6s"
                      dur="0.2s"
                      values="24;0"
                    ></animate>
                  </path>
                </g>
              </svg>
              <span>
                Email Us:{" "}
                <a
                  className="text-primary"
                  href="mailto:contact@freshmentapp.com"
                >
                  contact@freshmentapp.com
                </a>
              </span>
            </div>

            <p>
              Write to us on our Contact Us form on{" "}
              <a
                className="text-primary"
                target="_blank"
                href="https://freshmentapp.com"
              >
                freshmentapp.com
              </a>
            </p>
          </Modal.Body>
        </Modal>
      </Suspense>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
        <div
          onClick={() => setContactModal(true)}
          style={{ cursor: "pointer" }}
          class="floating-button"
        >
          <div class="bg-gradient-btn shrink">
            <div style={{ paddingRight: "15px" }}>
              <svg
                id="Capa_1"
                enable-background="new 0 0 512 512"
                height="30"
                viewBox="0 0 512 512"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
              >
                <g>
                  <path d="m483.637 213.16c-10.222-6.317-21.601-7.603-31.637-6.615v-10.545c0-107.799-88.214-196-196-196-107.799 0-196 88.214-196 196v10.545c-10.036-.989-21.416.298-31.637 6.615-18.821 11.632-28.363 36.139-28.363 72.84 0 36.762 9.56 61.399 28.415 73.228 9.366 5.876 20.304 7.917 31.585 6.947v10.825c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15v-181c0-8.284-6.716-15-15-15h-44.315c7.608-84.529 78.839-151 165.315-151s157.707 66.471 165.315 151h-44.315c-8.284 0-15 6.716-15 15v181c0 8.284 6.716 15 15 15h45v15c0 24.813-20.187 45-45 45h-48.58c-6.192-17.458-22.865-30-42.42-30h-30c-24.813 0-45 20.187-45 45s20.187 45 45 45h30c19.555 0 36.228-12.542 42.42-30h48.58c41.355 0 75-33.645 75-75 0-18.271 0-28.969 0-40.826 11.282.97 22.221-1.072 31.585-6.947 18.855-11.828 28.415-36.465 28.415-73.227 0-36.701-9.542-61.208-28.363-72.84zm-423.637 122.821c-4.786.833-10.768.891-15.642-2.167-9.259-5.808-14.358-22.789-14.358-47.814 0-24.785 4.985-41.569 14.036-47.259 4.894-3.077 11.06-2.858 15.964-1.89zm60-124.981v151h-30v-151zm166 271h-30c-8.271 0-15-6.729-15-15s6.729-15 15-15h30c8.271 0 15 6.729 15 15s-6.729 15-15 15zm136-120h-30v-151h30zm45.749-28.253c-4.848 3.095-10.896 3.035-15.749 2.194v-99.089c4.904-.968 11.069-1.187 15.964 1.89 9.051 5.689 14.036 22.473 14.036 47.258 0 24.923-5.062 41.88-14.251 47.747z"></path>
                </g>
              </svg>
            </div>
            <span class="ml-2 text-white hide-text show-text">
              <span class="letter">S</span>
              <span class="letter">U</span>
              <span class="letter">P</span>
              <span class="letter">P</span>
              <span class="letter">O</span>
              <span class="letter">R</span>
              <span class="letter">T</span>
            </span>
          </div>
        </div>
        <Modal
          show={contactModal}
          onHide={() => setContactModal(false)}
          centered
        >
          <Modal.Body className="p-4 position-relative">
            <h3 className="text-center mb-4">How can we help?</h3>
            <Button
              onClick={() => setContactModal(false)}
              variant=""
              className="btn-close position-absolute"
              style={{ top: "1.2rem", right: "1rem" }}
            ></Button>
            <div className="d-block text-center align-items-center mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12em"
                height="12em"
                viewBox="0 0 28 28"
              >
                <path
                  fill="currentColor"
                  d="M14 19a2 2 0 0 1-1.839-1.212a8 8 0 0 1-.951-.288l-.017-.006A8 8 0 0 1 8.708 16a8 8 0 1 1 13.257-6.75c.039.413-.3.75-.715.75c-.414 0-.745-.337-.793-.749A6.5 6.5 0 1 0 11.496 16l.04.017q.3.123.616.217a2 2 0 0 1 3.785 1.266A2 2 0 0 1 14 19m-7-1.5h1.169a9.6 9.6 0 0 1-1.518-1.48A3 3 0 0 0 4 19v.715C4 23.433 8.21 26 14 26s10-2.708 10-6.285V19a3 3 0 0 0-3-3h-3.645a3.5 3.5 0 0 1 .11 1.5H21l.145.007A1.5 1.5 0 0 1 22.5 19v.715l-.005.161c-.14 2.52-3.569 4.624-8.495 4.624c-5.111 0-8.5-2.111-8.5-4.785V19l.007-.145A1.5 1.5 0 0 1 7 17.5M19 10a5 5 0 0 1-2.644 4.411A3.5 3.5 0 0 0 14 13.5a3.5 3.5 0 1 0 0-7a3.5 3.5 0 0 0 0 7c-.908 0-1.734.346-2.355.912a5 5 0 0 1-1.932-1.838A5 5 0 1 1 19 10"
                ></path>
              </svg>
            </div>

            <div className="d-flex align-items-center mb-3">
              <svg
                className="me-3"
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                >
                  <path
                    strokeDasharray={64}
                    strokeDashoffset={64}
                    d="M8 3c0.5 0 2.5 4.5 2.5 5c0 1 -1.5 2 -2 3c-0.5 1 0.5 2 1.5 3c0.39 0.39 2 2 3 1.5c1 -0.5 2 -2 3 -2c0.5 0 5 2 5 2.5c0 2 -1.5 3.5 -3 4c-1.5 0.5 -2.5 0.5 -4.5 0c-2 -0.5 -3.5 -1 -6 -3.5c-2.5 -2.5 -3 -4 -3.5 -6c-0.5 -2 -0.5 -3 0 -4.5c0.5 -1.5 2 -3 4 -3Z"
                  >
                    <animate
                      fill="freeze"
                      attributeName="stroke-dashoffset"
                      dur="0.6s"
                      values="64;0"
                    ></animate>
                    <animateTransform
                      id="lineMdPhoneCallLoop0"
                      fill="freeze"
                      attributeName="transform"
                      begin="0.6s;lineMdPhoneCallLoop0.begin+2.7s"
                      dur="0.5s"
                      type="rotate"
                      values="0 12 12;15 12 12;0 12 12;-12 12 12;0 12 12;12 12 12;0 12 12;-15 12 12;0 12 12"
                    ></animateTransform>
                  </path>
                  <path
                    strokeDasharray={4}
                    strokeDashoffset={4}
                    d="M15.76 8.28c-0.5 -0.51 -1.1 -0.93 -1.76 -1.24M15.76 8.28c0.49 0.49 0.9 1.08 1.2 1.72"
                  >
                    <animate
                      fill="freeze"
                      attributeName="stroke-dashoffset"
                      begin="lineMdPhoneCallLoop0.begin+0s"
                      dur="2.7s"
                      keyTimes="0;0.111;0.259;0.37;1"
                      values="4;0;0;4;4"
                    ></animate>
                  </path>
                  <path
                    strokeDasharray={6}
                    strokeDashoffset={6}
                    d="M18.67 5.35c-1 -1 -2.26 -1.73 -3.67 -2.1M18.67 5.35c0.99 1 1.72 2.25 2.08 3.65"
                  >
                    <animate
                      fill="freeze"
                      attributeName="stroke-dashoffset"
                      begin="lineMdPhoneCallLoop0.begin+0.2s"
                      dur="2.7s"
                      keyTimes="0;0.074;0.185;0.333;0.444;1"
                      values="6;6;0;0;6;6"
                    ></animate>
                  </path>
                </g>
              </svg>
              <span>
                Contact Us: <a href="tel:8355907853">8355907853</a>
              </span>
            </div>

            <div className="d-flex align-items-center mb-3">
              <svg
                className="me-3"
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  fillOpacity={0}
                  d="M12 11l-8 -5h16l-8 5Z"
                >
                  <animate
                    fill="freeze"
                    attributeName="fill-opacity"
                    begin="0.8s"
                    dur="0.15s"
                    values="0;0.3"
                  ></animate>
                </path>
                <g
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                >
                  <path
                    strokeDasharray={64}
                    strokeDashoffset={64}
                    d="M4 5h16c0.55 0 1 0.45 1 1v12c0 0.55 -0.45 1 -1 1h-16c-0.55 0 -1 -0.45 -1 -1v-12c0 -0.55 0.45 -1 1 -1Z"
                  >
                    <animate
                      fill="freeze"
                      attributeName="stroke-dashoffset"
                      dur="0.6s"
                      values="64;0"
                    ></animate>
                  </path>
                  <path
                    strokeDasharray={24}
                    strokeDashoffset={24}
                    d="M3 6.5l9 5.5l9 -5.5"
                  >
                    <animate
                      fill="freeze"
                      attributeName="stroke-dashoffset"
                      begin="0.6s"
                      dur="0.2s"
                      values="24;0"
                    ></animate>
                  </path>
                </g>
              </svg>
              <span>
                Email Us:{" "}
                <a
                  className="text-primary"
                  href="mailto:contact@freshmentapp.com"
                >
                  contact@freshmentapp.com
                </a>
              </span>
            </div>

            <p>
              Write to us on our Contact Us form on{" "}
              <a
                className="text-primary"
                target="_blank"
                href="https://freshmentapp.com"
              >
                freshmentapp.com
              </a>
            </p>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default connect(mapStateToProps)(App);
