import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from "react-bootstrap";
import axios from "axios";

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import CanvasChartTab from "../Jobick/Home/CanvasChartTab";
import FeaturedCompanies from "../Jobick/Home/FeaturedCompanies";
import RecentActivity from "../Jobick/Home/RecentActivity";
import AvailableJobs from "../Jobick/Home/AvailableJobs";
import HomeSlider from "../Jobick/Home/HomeSlider";
import { API_URL } from "../../../config";
import { useRef } from "react";
import { useSelector } from "react-redux";

//Images
//import pic1 from './../../../images/profile/pic1.jpg';

//Components
const NewCustomers1 = loadable(() =>
  pMinDelay(import("../Jobick/Home/NewCustomers1"), 1000)
);
const NewCustomers2 = loadable(() =>
  pMinDelay(import("../Jobick/Home/NewCustomers2"), 1000)
);
const NewCustomers3 = loadable(() =>
  pMinDelay(import("../Jobick/Home/NewCustomers3"), 1000)
);
const NewCustomers4 = loadable(() =>
  pMinDelay(import("../Jobick/Home/NewCustomers4"), 1000)
);

export const Home = () => {
  const [dataList, setData] = useState([]);
  const collegeId = useSelector((state) => state.college_id.college_id);

  const [jobsdataList, setJobsData] = useState([]);
  // const userDetails = localStorage.getItem("userDetails");
  // const collegeProfile = JSON.parse(userDetails);
  const payload = { college_id: collegeId };
  const jobspayload = { limit: 8, offset: 0, category_name: "Technology" };
  const [recentactivity, setRecentActivity] = useState([]);
  const [message, setMessage] = useState(null);
  const [Loading, setLoading] = useState(false);
  const fetched = useRef(false);
  const isFetched = useRef(false);
  const isActivityFetched = useRef(false);
  const isDashboardFetched = useRef(false);
  // useEffect(() => {}, []);

  // useEffect(() => {}, []);

  const fetchDashboard = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;
    // console.log(authToken);
    await axios
      .get(`${API_URL}/api/freshment_portal_apis/Dashboard/get_dashboard`, {
        params: payload,
        headers: {
          Authorization: authToken,
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Headers":
          //   "Origin, X-Requested-With, Content-Type, Accept, Authorization",
          // "Content-Type": "application/json",
          // "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        },
      })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((err) => console.log("err======", err));
  };

  useEffect(() => {
    if (!isFetched.current) {
      isFetched.current = true;
      fetchDashboard();
    }
  }, []);

  const fetchActivity = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;
    // console.log(authToken);
    setLoading(true);
    await axios
      .get(
        `${API_URL}/api/freshment_portal_apis/Dashboard/get_recent_activity`,
        {
          params: payload,
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((response) => {
        setRecentActivity(response.data.data);
        setMessage(response.data.message);
        setLoading(false);
      })
      .catch((err) => console.log("err======", err));
  };
  useEffect(() => {
    if (!isActivityFetched.current) {
      isActivityFetched.current = true;
      fetchActivity();
    }
  }, []); // Ensure correct dependencies

  const getJobListHome = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const authToken = userDetails?.data?.auth_token;
    // console.log(authToken);
    await axios
      .get(
        `${API_URL}/api/freshment_portal_apis/Dashboard/get_latest_job_list_home`,
        { params: jobspayload, headers: { Authorization: authToken } }
      )
      .then((response) => {
        setJobsData(response.data.data);
      })
      .catch((err) => console.log("err======", err));
  };
  useEffect(() => {
    if (!isDashboardFetched.current) {
      isDashboardFetched.current = true;
      getJobListHome();
    }
  }, []);
  const { changeBackground, background } = useContext(ThemeContext);
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);
  const [control3, setControl3] = useState("Newest");
  return (
    <>
      <style jsx>{`
        .text-wrap {
          word-wrap: break-word; /* Allow long words to break */
          overflow-wrap: break-word; /* Compatible for older browsers */
          max-width: 140px; /* Adjust max-width as needed */
        }
        @media only screen and (min-width: 650px) {
          .text-wrap {
            max-width: 100%;
          }
        }
        @media only screen and (min-width: 1200px) {
          .text-wrap {
            max-width: 95px;
          }
        }
        @media only screen and (min-width: 1700px) {
          .text-wrap {
            max-width: 100%;
          }
        }
      `}</style>
      <div className="row">
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="row separate-row">
                    {/* First Column */}
                    <div className="col-sm-6 col-lg-6">
                      <div className="job-icon pb-4 d-flex justify-content-between">
                        <div className="text-wrap">
                          <h3 className="mb-0">{dataList.total_students}</h3>
                          <span className="fs-14 d-block mb-2">
                            Total Students
                          </span>
                        </div>
                        <div id="NewCustomers">
                          <NewCustomers1 />
                        </div>
                      </div>
                    </div>
                    {/* Second Column */}
                    <div className="col-sm-6 col-lg-6">
                      <div className="job-icon pb-4 pt-4 pt-sm-0 d-flex justify-content-between">
                        <div className="text-wrap">
                          <h3 className="mb-0">{dataList.total_jobs}</h3>
                          <span className="fs-14 d-block mb-2">Total Jobs</span>
                        </div>
                        <div id="NewCustomers1">
                          <NewCustomers2 />
                        </div>
                      </div>
                    </div>
                    {/* Third Column */}
                    <div className="col-sm-6 col-lg-6">
                      <div className="job-icon pt-4 pb-sm-0 pb-4 pe-3 d-flex justify-content-between">
                        <div className="text-wrap">
                          <h3 className="mb-0">{dataList.total_offers}</h3>
                          <span className="fs-14 d-block mb-2">
                            Total Offers
                          </span>
                        </div>
                        <div id="NewCustomers2">
                          <NewCustomers3 />
                        </div>
                      </div>
                    </div>
                    {/* Fourth Column */}
                    <div className="col-sm-6 col-lg-6">
                      <div className="job-icon pt-4 d-flex justify-content-between">
                        <div className="text-wrap">
                          <h3 className="mb-0">
                            {dataList.highest_package
                              ? dataList?.highest_package >= 10000000 // 1 crore in paise
                                ? (
                                    dataList?.highest_package / 10000000
                                  ).toFixed(2) // Convert to crore
                                : (dataList?.highest_package / 100000).toFixed(
                                    2
                                  ) // Convert to lakh
                              : "0"}
                            {dataList?.highest_package >= 10000000
                              ? " Cr"
                              : " LPA"}
                          </h3>
                          <span className="fs-14 d-block mb-2">
                            Highest Package
                          </span>
                        </div>
                        <div id="NewCustomers3">
                          <NewCustomers4 />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-12">
              {/* <VacanyTab /> */}
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header pb-0 border-0 flex-wrap">
                    <h4 className="fs-20 mb-0">Available Jobs For You</h4>
                    <div className="d-flex">
                      {/* <Dropdown className="default-select bgl-primary rounded">
                        <Dropdown.Toggle
                          as="div"
                          className="btn btn-sm  text-primary d-flex align-items-center i-false"
                        >
                          {control3}
                          <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
                          <Dropdown.Item
                            className=" text-primary"
                            onClick={() => setControl3("Newest")}
                          >
                            Newest
                          </Dropdown.Item>
                          <Dropdown.Item
                            className=" text-primary"
                            onClick={() => setControl3("Oldest")}
                          >
                            Oldest
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                      <a
                        href="/job-list"
                        className="btn btn-sm text-white btn-primary d-flex align-items-center i-false"
                      >
                        View All
                      </a>
                      {/* <Dropdown className="custom-dropdown mb-0">
                        <Dropdown.Toggle
                          as="button"
                          className="btn sharp tp-btn dark-btn i-false"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                              stroke="#342E59"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                              stroke="#342E59"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                              stroke="#342E59"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-right">
                          <Dropdown.Item>Details</Dropdown.Item>
                          <Dropdown.Item className="text-danger">
                            Cancel
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="owl-carousel owl-carousel owl-loaded front-view-slider ">
                      <AvailableJobs jobsdata={jobsdataList} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-12">
							<CanvasChartTab />
						</div> */}
          </div>
        </div>
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-12">
              {/* <RecentActivity /> */}
              <div className="card">
                <div className="card-header border-0">
                  <h4 className="fs-20 mb-0">Recent Activity</h4>
                  <div className="d-flex">
                    {recentactivity && recentactivity.length > 0 ? (
                      <a
                        href="/students-activity"
                        className="btn btn-sm text-white btn-primary d-flex align-items-center i-false"
                      >
                        View All
                      </a>
                    ) : null}

                    {/* <Dropdown className="default-select bgl-primary rounded">
              <Dropdown.Toggle
                as="div"
                className="btn btn-sm  text-primary d-flex align-items-center i-false"
              >
                {control3}
                <i className="fas fa-angle-down text-primary scale5 ms-3"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
                <Dropdown.Item
                  className=" text-primary"
                  onClick={() => setControl3("Newest")}
                >
                  Newest
                </Dropdown.Item>
                <Dropdown.Item
                  className=" text-primary"
                  onClick={() => setControl3("Oldest")}
                >
                  Oldest
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="custom-dropdown mb-0">
              <Dropdown.Toggle
                as="button"
                className="btn sharp tp-btn dark-btn i-false"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                    stroke="#342E59"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                    stroke="#342E59"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                    stroke="#342E59"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item>Details</Dropdown.Item>
                <Dropdown.Item className="text-danger">Cancel</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
                  </div>
                </div>
                <div
                  className="card-body loadmore-content  recent-activity-wrapper"
                  id="RecentActivityContent"
                >
                  {Loading ? (
                    <div className="text-center my-4">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : recentactivity && recentactivity.length > 0 ? (
                    recentactivity.map((item, index) => (
                      <div className="d-flex recent-activity" key={index}>
                        {console.log(item)}
                        <span className="me-3 activity">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                          >
                            <circle cx="8.5" cy="8.5" r="8.5" fill="#f93a0b" />
                          </svg>
                        </span>
                        {/* <img src={item.image} alt="" /> */}
                        <div className="ms-3">
                          {/* <h5 className="mb-1">{item.user_name}</h5> */}
                          {item.message ? (
                            <p>{item.message} </p>
                          ) : (
                            <p>
                              {item?.user_name} Has {item?.analysis_type} the{" "}
                              {item?.job_name}
                            </p>
                          )}
                          {/* <span></span> */}
                          {/* <span>8min ago</span> */}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      <p>{message}</p>
                    </div>
                  )}
                </div>
                {/* <div className="card-footer border-0 m-auto pt-0">
					<Link to={"#"} className="btn btn-outline-primary btn-rounded m-auto dlab-load-more"  onClick={() => onClick()}>
						View more{" "}
						{refresh && <i className="fa fa-refresh"></i>}
					</Link>
				</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
