import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { EditorWatchdog } from "@ckeditor/ckeditor5-watchdog";

const editorWatchdog = new EditorWatchdog();

const CkEditorBlog = ({ data, onChange }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={data}
      onChange={(event, editor) => onChange(event, editor)}
      // onReady={(editor) => {
      //   editorWatchdog.setEditor(editor);
      //   console.log("Editor is ready to use!", editor);
      // }}
      // onChange={(event, editor) => {
      //   const data = editor.getData();
      //   console.log({ event, editor, data });
      // }}
    />
  );
};

export default CkEditorBlog;
