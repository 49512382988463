import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
  TwoFactorAuthDerive,
} from "../../store/actions/AuthActions";
import axios from "axios";

// image
import logo from "../../images/freshment.png";
import freshmentlogo from "../../images/freshmentLogo1.png";
import loginbg from "../../images/lg_page_main_photo.png";
import { API_URL } from "../../config";
import Swal from "sweetalert2";
import jobLogo from "../../images/jobs_icon.png";
import studentLogo from "../../images/students_icon.png";
import gesia from "../../images/Gesia.png";
import iitkharagpur from "../../images/iitkharagpur3 (1).png";
// import collegeLogo from "../../images/college_logo_footer.png";
import collegeprofileplaceholder from "../../images/college_profile_placeholder2.png";
function Login(props) {
  let history = useHistory();
  const [college_email, setEmail] = useState("");
  const [college_password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false); // Track "Remember me"
  const [errors, setErrors] = useState({
    college_email: "",
    college_password: "",
  });
  const [ipAddress, setIpAddress] = useState("");
  const [location, setLocation] = useState("");
  const dispatch = useDispatch();

  // Fetch IP address and location
  useEffect(() => {
    const fetchIpAndLocation = async () => {
      try {
        const { data } = await axios.get("https://ipapi.co/json/"); // Use any IP-geo API
        setIpAddress(data.ip);
        setLocation(`${data.city}, ${data.country_name}`);
      } catch (error) {
        console.error("Error fetching IP or location:", error);
      }
    };
    fetchIpAndLocation();

    // Load saved credentials if "Remember me" was selected
    const savedEmail = localStorage.getItem("college_email");
    const savedPassword = localStorage.getItem("college_password");
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true); // Ensure the checkbox is also checked
    }
  }, []);

  const handleEmail = (e) => setEmail(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);
  const handleRememberMe = (e) => setRememberMe(e.target.checked); // Track checkbox

  const getDeviceInfo = () => {
    if (navigator.userAgentData) {
      return navigator.userAgentData.brands
        .map((brand) => `${brand.brand} ${brand.version}`)
        .join(", ");
    } else {
      return navigator.userAgent; // Fallback to userAgent
    }
  };

  const validateInputs = () => {
    let errorMessages = [];
    if (!college_email) {
      errorMessages.push("Email is Required");
    }
    if (!college_password) {
      errorMessages.push("Password is Required");
    }
    return errorMessages;
  };

  const handleApi = async (e) => {
    e.preventDefault();

    const deviceInfo = getDeviceInfo(); // Get device and browser info
    const payload = { college_email, college_password };
    const params = new URLSearchParams(payload);

    const errorMessages = validateInputs(); // Validate inputs

    if (errorMessages.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: errorMessages.join(", "),
      });
      return;
    }

    params.append("ip_address", ipAddress);
    params.append("device_info", deviceInfo);
    params.append("location", location);

    try {
      const result = await axios.post(
        `${API_URL}/api/freshment_portal_apis/College/login`,
        params
      );
      // console.log(result);
      if (result.data.success) {
        onLogin(e, result.data, ipAddress, deviceInfo, location);
      } else {
        if (result.data.is_locked) {
          Swal.fire({
            title: "Locked Out!",
            html: `
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lock-icon"
              >
                <path d="M17 11V7a5 5 0 0 0-10 0v4" />
                <rect x="5" y="11" width="14" height="10" rx="2" />
                <line x1="12" y1="15" x2="12" y2="17" class="lock-key" />
                <line x1="10" y1="17" x2="14" y2="17" class="lock-key" />
              </svg>
              <p class="mt-3">${result.data.message}</p>
            `,
            text: result.data.message,
          });

          // Apply styles separately
          const style = document.createElement("style");
          style.textContent = `
            .lock-icon {
              animation: scale 0.5s infinite alternate;
            }
      
            @keyframes scale {
              0% {
                transform: scale(1);
              }
              100% {
                transform: scale(1.1);
              }
            }
      
            .lock-key {
              stroke: #000; /* Change the color as needed */
            }
          `;
          document.head.appendChild(style);
        } else if (result.data.in_active) {
          Swal.fire({
            title: "Oops..",
            // icon: "warning",
            html: `
              <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><path fill="currentColor" d="M10 4a4 4 0 0 0-4 4a4 4 0 0 0 4 4a4 4 0 0 0 4-4a4 4 0 0 0-4-4m7.5 9C15 13 13 15 13 17.5s2 4.5 4.5 4.5s4.5-2 4.5-4.5s-2-4.5-4.5-4.5M10 14c-4.42 0-8 1.79-8 4v2h9.5a6.5 6.5 0 0 1-.5-2.5a6.5 6.5 0 0 1 .95-3.36c-.63-.08-1.27-.14-1.95-.14m7.5.5c1.66 0 3 1.34 3 3c0 .56-.15 1.08-.42 1.5L16 14.92c.42-.27.94-.42 1.5-.42M14.92 16L19 20.08c-.42.27-.94.42-1.5.42c-1.66 0-3-1.34-3-3c0-.56.15-1.08.42-1.5"></path></svg>
              <p class="mt-3">${result.data.message}</p>
            `,
            text: result.data.message,
          });

          // Apply styles separately
          const style = document.createElement("style");
          style.textContent = `
            .lock-icon {
              animation: scale 0.5s infinite alternate;
            }
      
            @keyframes scale {
              0% {
                transform: scale(1);
              }
              100% {
                transform: scale(1.1);
              }
            }
      
            .lock-key {
              stroke: #000; /* Change the color as needed */
            }
          `;
          document.head.appendChild(style);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops..",
            text: result.data.message,
          });
        }
      }
    } catch (error) {
      console.error("Login error:", error);
      Swal.fire({
        icon: "error",
        title: "Oops..",
        text: "Something went wrong!",
      });
    }
  };

  const onLogin = (e, result, ipAddress, deviceInfo, location) => {
    e.preventDefault();

    if (rememberMe) {
      localStorage.setItem("college_email", college_email);
      localStorage.setItem("college_password", college_password);
    } else {
      localStorage.removeItem("college_email");
      localStorage.removeItem("college_password");
    }

    dispatch(loadingToggleAction(true));
    dispatch(
      TwoFactorAuthDerive(
        result,
        props.history,
        ipAddress,
        deviceInfo,
        location
      )
    );
  };

  return (
    <>
      <div className="w-100">
        <div className="d-flex flex-column w-100" style={{ height: "100vh" }}>
          <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
            <div className="login-aside text-center  d-flex flex-column flex-row-auto">
              <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
                <div className="text-center mb-4 pt-5">
                  <img
                    className="main-logo"
                    style={{ height: "100%", width: "350px" }}
                    src={freshmentlogo}
                    alt=""
                  />
                </div>
                <h3 className="mb-2">Welcome back!</h3>
                <p>
                  Bridging Colleges, Companies & Careers <br />
                  One Platform, Endless Opportunities.
                </p>
              </div>
              <div
                className="aside-image"
                style={{ backgroundImage: "url(" + loginbg + ")" }}
              ></div>
            </div>
            <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
              <div className="d-flex justify-content-center h-100 align-items-center">
                <div className="authincation-content style-2">
                  <div className="row no-gutters">
                    <div className="col-xl-12 tab-content">
                      <div id="sign-in" className="auth-form form-validation">
                        <form onSubmit={onLogin} className="form-validate">
                          <h3 className="text-center mb-4 text-black">
                            Sign in your account
                          </h3>
                          <div className="form-group mb-3">
                            <label className="mb-1" htmlFor="val-email">
                              <strong>Email</strong>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              value={college_email}
                              onChange={handleEmail}
                              placeholder="Type Your Email Address"
                            />
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-1">
                              <strong>Password</strong>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              value={college_password}
                              onChange={handlePassword}
                              placeholder="Type Your Password"
                            />
                          </div>
                          <div className="form-row d-flex justify-content-between mt-4 mb-2">
                            <div className="form-group mb-3">
                              <div className="custom-control custom-checkbox ml-1">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="basic_checkbox_1"
                                  checked={rememberMe}
                                  onChange={handleRememberMe}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="basic_checkbox_1"
                                >
                                  Remember my preference
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="text-center form-group mb-3">
                            <button
                              onClick={handleApi}
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              Sign In
                            </button>
                          </div>
                        </form>
                        <div className="new-account mt-3">
                          <p>
                            <span className="me-2">Forgot Password??</span>
                            <Link
                              className="text-primary"
                              to="./page-forgot-password"
                            >
                              Click Here
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer
            className="text-center"
            style={{ width: "100%", backgroundColor: "#fff" }}
          >
            <div className="container footer-container">
              <div className="row mt-0 mb-3">
                <div
                  className="col-sm-12 mt-4 col-lg-12 col-xl-6 left-footer-container-main"
                  style={{ padding: "0 40px 0 0" }}
                >
                  <p
                    className="fs-18 left-footer-title "
                    style={{ fontWeight: "bold", textWrap: "nowrap" }}
                  >
                    Featured In GESIA, IIT-Kharagpur Top 5 Emerging Start-ups
                  </p>
                  <div
                    style={{
                      padding: "0 40px",
                      borderRight: "1px solid #cccccc",
                      marginRight: "-60px",
                    }}
                    className="left-footer-container d-flex align-items-start justify-content-center"
                  >
                    <div
                      className="d-flex left-footer-img-container align-items-center justify-content-end me-5"
                      style={{ flexWrap: "wrap" }}
                    >
                      <img
                        className="img-footer-left-0"
                        src={iitkharagpur}
                        style={{
                          marginRight: 37,
                          width: 250,
                          height: "100%",
                        }}
                      />
                      <img
                        className="img-footer-left-1"
                        src={gesia}
                        style={{
                          marginRight: 37,
                        }}
                        width={50}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col-sm-12 mt-4 col-lg-12 col-xl-6 right-footer-container-main"
                  style={{ padding: "0 0 0 40px" }}
                >
                  <p
                    className="fs-18"
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Trusted by 2500+ Colleges & 50+ Universities
                  </p>
                  <div
                    style={{ padding: "0 0 0 40px", marginLeft: 15 }}
                    className="d-flex align-items-start img-container-footer-right justify-content-start"
                  >
                    <div className="d-flex align-items-center me-5">
                      <img
                        className="img-footer-right"
                        src={studentLogo}
                        style={{
                          marginRight: 17,
                        }}
                      />
                      <div className="d-block" style={{ textAlign: "left" }}>
                        <p
                          className="fs-16 mb-1 "
                          style={{ textWrap: "nowrap" }}
                        >
                          {" "}
                          <strong>1 Lakhs+</strong>
                        </p>
                        <p className="fs-14 text-muted mb-0">Users</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center me-5">
                      <img
                        className="img-footer-right"
                        src={jobLogo}
                        style={{
                          marginRight: 17,
                        }}
                      />
                      <div className="d-block" style={{ textAlign: "left" }}>
                        <p className="fs-16 mb-1">
                          {" "}
                          <strong>5000+</strong>
                        </p>
                        <p className="fs-14 text-muted mb-0">Jobs</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center me-5">
                      <img
                        className="img-footer-right"
                        src={collegeprofileplaceholder}
                        style={{
                          marginRight: 27,
                        }}
                        width={90}
                      />
                      <div className="d-block" style={{ textAlign: "left" }}>
                        <p className="fs-16 mb-1">
                          {" "}
                          <strong>2500+</strong>
                        </p>
                        <p className="fs-14 text-muted mb-0">Colleges</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  errorMessage: state.auth.errorMessage,
  successMessage: state.auth.successMessage,
  showLoading: state.auth.showLoading,
});

export default connect(mapStateToProps)(Login);
