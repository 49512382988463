export const setCollegeId = (collegeId) => ({
  type: "SET_COLLEGEID",
  payload: collegeId,
});

// actions/collegeActions.js
export const clearCollegeId = () => {
  localStorage.removeItem("college_id");
  return { type: "CLEAR_COLLEGEID" };
};
